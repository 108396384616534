import React from "react"
import logo from "../../../assets/img/logo/logo.png"
import "../../../assets/scss/components/app-loader.scss"
import { Col, Row } from "react-bootstrap"
class SpinnerComponent extends React.Component {
  render() {
    return (
      <div className="fallback-spinner vh-100">
        <Row>
          <Col md="6">
            <img src="https://repomassimagenes.sfo3.digitaloceanspaces.com/formulario-de-tramite-cesion/2024/10/31/documento-24-1-foto_interna_del_predio.png" alt="logo" className="img-fluid" ></img>
          </Col>
          <Col md="6">
            <img src="https://repomassimagenes.sfo3.digitaloceanspaces.com/formulario-de-tramite-cesion/2024/10/31/documento-24-2-foto_externa_del_predio.jpg" alt="logo" className="img-fluid" ></img>
          </Col>
        </Row>
        <div className="loading">
          <div className="effect-1 effects"></div>
          <div className="effect-2 effects"></div>
          <div className="effect-3 effects"></div>
        </div>
      </div>
    )
  }
}

export default SpinnerComponent
