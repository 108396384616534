import React from "react"
// import ScrollToTop from "react-scroll-up"


const Footer = props => {
  return (
    <footer>

    </footer>
  )
}

export default Footer
