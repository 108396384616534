import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import logo from "./../../../../assets/img/logo/logo-white.png";
import { Col, Row } from "react-bootstrap";

class SidebarHeader extends Component {
  render() {
    let { menuShadow } = this.props;
    return (
      <div className="navbar-header" style={{ height: "100px" }}>
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item mr-auto" style={{ marginLeft: "auto" }}>
            <NavLink to="/home" className="navbar-brand">
              <Row>
                  <img
                    style={{ width: "100px", height: "100px" }}
                    src="https://repomassimagenes.sfo3.digitaloceanspaces.com/formulario-de-tramite-cesion/2024/10/31/documento-24-1-foto_interna_del_predio.png"
                    alt="logo"
                    className="img-fluid"
                  ></img>

              </Row>
            </NavLink>
          </li>
        </ul>
        <div
          className={classnames("shadow-bottom", {
            "d-none": menuShadow === false,
          })}
        />
      </div>
    );
  }
}

export default SidebarHeader;
